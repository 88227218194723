import React from "react";
import ProductItem from "./ProductItem";
import { useStateValue } from './context/StateProvider'

function ProductList({  removeProduct, editProduct }) {

  const [{products }, dispatch] = useStateValue();

  return (
    <div className="p-6">
      <h2 className="text-xl font-bold mb-4 text-center">Depodaki Ürünler</h2>
      {products.length > 0 ? (
        <>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-900 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-orange-300 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Ürün Adı
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Miktar
                  </th>
                  <th scope="col" className="px-10 py-3 flex justify-end  ">
                    Düzenle
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <ProductItem
                    key={product.id}
                    product={product}
                    removeProduct={removeProduct}
                    editProduct={editProduct}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        null
      )}
    </div>
  );
}

export default ProductList;
