import React, { useState, useEffect } from 'react';
import { updateProductToFirestore } from './utils/FirebaseFunction'

function ProductForm({ addProduct, editingProduct, updateProduct }) {
  const [productCode, setProductCode] = useState('');
  const [name, setName] = useState('');
  const [quantity, setQuantity] = useState('');

  // If editingProduct exists, set form to editing mode
  useEffect(() => {
    if (editingProduct) {
      setProductCode(editingProduct.productCode)
      setName(editingProduct.name);
      setQuantity(editingProduct.quantity);
    }
  }, [editingProduct]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editingProduct) {
      // Update product in Firestore
      
      try {

        updateProductToFirestore({ id: editingProduct.id, name, quantity })

        // Update local state
        updateProduct({ id: editingProduct.id, name, quantity });
      } catch (error) {
        console.error('Error updating product:', error);
      }
    } else {
      // Add new product
      addProduct({ id: Date.now(), name, quantity });
    }

    // Reset form fields
    setName('');
    setQuantity('');
  };

  

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md mx-auto p-4 border border-gray-300 rounded-lg shadow-lg bg-white"
    >
      <h2 className="text-lg font-semibold mb-4">
        {editingProduct ? 'Ürünü Düzenle' : 'Yeni Ürün Ekle'}
      </h2>
      {/* <div className="mb-4">
        <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="name">
          Ürün kodu
        </label>  
        <input
          type="text"
          id="urunkodu"
          placeholder="Ürün kodu"
          value={productCode}
          onChange={(e) => setName(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div> */}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="name">
          Ürün adı
        </label>  
        <input
          type="text"
          id="name"
          placeholder="Ürün adı"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="quantity">
          Miktar
        </label>
        <input
          type="number"
          id="quantity"
          placeholder="Miktar"
          value={quantity}
          onChange={(e) => {
            const value = e.target.value;
            if (value >  0) { 
              setQuantity(e.target.value = value);
            } else { 
              setQuantity(e.target.value = "")
            }
          }}

          // onChange={(e) => setQuantity(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <button
        type="submit"
        className="w-full px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {editingProduct ? 'Düzenle' : 'Ekle'}
      </button>
    </form>
  );
}

export default ProductForm;
