import React, { useEffect, useState } from "react";
import ProductForm from "./ProductForm";
import ProductList from "./ProductList";
import SearchBar from "./SearchBar";
import {
  saveProductToFirestore,
  getAllProducts,
} from "./utils/FirebaseFunction";
import { useStateValue } from "./context/StateProvider";
import { actionType } from "./context/reducer";

import "./css/index.css"; // Ensure this file imports Tailwind CSS
import LoginForm from "./utils/LoginForm";


function AdminPage() {
  const [{ products }, dispatch] = useStateValue();

  const [product, setProduct] = useState({});
  const [editingProduct, setEditingProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      saveProductToFirestore(product);
    }
  }, [initialLoad, product]);

  const addProduct = (product) => {
    setProduct(product);
  };

  const removeProduct = (id) => {
    dispatch({
      type: actionType.SET_PRODUCTS,
      products: products.filter((product) => product.id !== id),
    });
  };

  const editProduct = (id) => {
    const productToEdit = products.find((product) => product.id === id);
    setEditingProduct(productToEdit);
  };

  const handleSearch = (term) => {
    setSearchTerm(term.toLowerCase());
  };

  const updateProduct = (updatedProduct) => {
    dispatch({
      type: actionType.SET_PRODUCTS,
      products: products.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product
      ),
    });
    setEditingProduct(null);
  };

  const filteredProducts = products.filter((product) => {
    return product.name && product.name.toLowerCase().includes(searchTerm);
  });

  const getData = async () => {
    try {
      const data = await getAllProducts();
      dispatch({
        type: actionType.SET_PRODUCTS,
        products: data,
      });
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <div className="App p-6 bg-gray-200 min-h-screen flex flex-col items-center">
      <h3 className="text-4xl font-bold mb-6 text-gray-800">
        Halit Eniştemin Deposu
      </h3>
      
      <LoginForm/>

      <div className="w-full max-w-md mx-auto ">
        <ProductForm
          addProduct={addProduct}
          editingProduct={editingProduct}
          updateProduct={updateProduct}
        />
      </div>

      <div className="w-full max-w-md  mb-6 pt-5">
        <SearchBar onSearch={handleSearch} />
        {searchTerm && filteredProducts.length === 0 && (
          <p className="text-red-500 mt-4">Ürün mevcut değil.</p>
        )}
      </div>

      <div className="w-full max-w-3xl  ">
        <ProductList
          products={filteredProducts}
          removeProduct={removeProduct}
          editProduct={editProduct}
        />
      </div>

      <button
        onClick={getData}
        className="px-4  py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
      >
        Yazdır
      </button>
    </div>
  );
}

export default AdminPage;
