import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase-config";

// Get ALL producta from Firestore
export const getAllProducts = async () => {
  try {
    const items = await getDocs(
      query(collection(firestore, "Products"), orderBy("name"))
    );
    return items.docs.map((doc) => doc.data());
  } catch (error) {
    console.error("Error fetching products from Firestore:", error);
    return [];
  }
};

export const saveUserToFirestore = async (providerData) => {
  await setDoc(doc(firestore, "users", providerData.uid), providerData, {merge: true,});
};

// Saving new Item
export const saveProductToFirestore = async (data) => {
  await setDoc(doc(firestore, "Products", `${data.id}`), data, { merge: true });
  
};

// Update product in Firestore
export const updateProductToFirestore = async ({ id, name, quantity }) => {
  const productRef = doc(firestore, "Products", id.toString());
  await updateDoc(productRef, {
    name: name,
    quantity: quantity,
  });
};

export const deleteProductFromFirestore = async (product) => {
  // Reference to the document in Firestore
  const productRef = doc(firestore, "Products", product.id.toString());

  // Delete the document
  await deleteDoc(productRef);
};
