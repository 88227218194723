import React from "react";
import {deleteProductFromFirestore } from "./utils/FirebaseFunction";
 
function ProductItem({ product, removeProduct, editProduct }) {
  const handleDelete = async () => {
    const confirmDelete = window.confirm(`${product.name} adlı ürünü silmek istediğinizden emin misiniz?`);

   if(confirmDelete ) try {
      // // Reference to the document in Firestore
      deleteProductFromFirestore(product);
   
      // Call the local removeProduct function to update UI
      removeProduct(product.id);
    } catch (error) {
      console.error("Error removing product:", error);
    }
  };

  return (
    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
      <td className="px-6 py-4 text-blue-700 font-semibold dark:text-blue-400">
        {product.name}
      </td>
      <td className="px-6 py-4 text-blue-700 font-semibold dark:text-blue-400">
        {product.quantity}
      </td>
      <td className="px-6 py-4">
        <div className="flex justify-end space-x-4">
          <button
            className="font-medium text-green-600 dark:text-green-400 hover:text-green-700 dark:hover:text-green-500"
            onClick={() => editProduct(product.id)}
          >
            Düzenle
          </button>
          <button
            className="font-medium text-red-400 dark:text-red-500 hover:text-red-700 dark:hover:text-red-100 px-2"
            onClick={() => handleDelete()}
          >
            Sil
          </button>
        </div>
      </td>
    </tr>
  );
}

export default ProductItem;
