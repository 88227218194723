import { useEffect, useState } from "react";
import { signInWithPopup, signOut, browserSessionPersistence, setPersistence } from "firebase/auth";
import { firebaseAuth, provider } from "../firebase-config";
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";
import { saveUserToFirestore } from "./FirebaseFunction";
import { useNavigate } from "react-router-dom";

function LoginForm() {
  const [{ user, userIdhasAccess }, dispatch] = useStateValue();
  const [isLoggedin, setisLoggedin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.uid === userIdhasAccess) {
      setisLoggedin(true);
    } else {
      setisLoggedin(false);
      
    }
  }, [user]);

  const loginlogout = async () => {
    if (!user) {
      try {
        // Enforce session-based persistence to avoid cached credentials
        await setPersistence(firebaseAuth, browserSessionPersistence);

        // Perform sign-in, this will pop-up the sign-in window
        const result = await signInWithPopup(firebaseAuth, provider);

        const userfromfirestore = result.user;
        const providerData = userfromfirestore.providerData[0];
        
        if(providerData.uid !== userIdhasAccess) {  
          
             await signOut(firebaseAuth);

              } else { 
        // Dispatch user data into global state
            dispatch({
                type: actionType.SET_USER,
                user: providerData,
              });
   
        // Save user to Firestore (optional)
        saveUserToFirestore(providerData);
        navigate("/admin");
      }

      } catch (error) {
        console.error("Sign-in error:", error);
      }
    } else {
      try {
        // Perform sign out
        await signOut(firebaseAuth);

        // Clear Firebase Auth user cache from localStorage and sessionStorage
        localStorage.removeItem("firebase:authUser:");
        sessionStorage.removeItem("firebase:authUser:");

        // Clear global state
        dispatch({
          type: actionType.SET_USER,
          user: null,
        });

        // console.log("Sign-out successful.");
        navigate("/");
      } catch (error) {
        console.error("Sign-out error:", error);
      }
    }
  };

  return (
    <button
      onClick={loginlogout}
      className="absolute top-4 right-4 px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
    >
      {isLoggedin ? "Çıkış Yap" : "Giriş Yap"}
    </button>
  );
}

export default LoginForm;
