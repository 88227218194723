import { Outlet, Navigate } from "react-router-dom";
import { useStateValue } from "../context/StateProvider"; 

const ProtectedRoutes = () => {
    const [{ user, userIdhasAccess }, dispatch] = useStateValue();

    
if(user && user.uid === userIdhasAccess) {
    return <Outlet />

}   
else {
    return <Navigate to="/" />
    } 
}

export default ProtectedRoutes