 
import React from 'react';

function SearchBar({ onSearch }) {
  return (
    <input
      className="w-full   p-3 text-sm text-gray-700 placeholder-gray-500 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300 ease-in-out"
      type="text"
      placeholder="Ürün ara..."
      onChange={(e) => onSearch(e.target.value)} 
    />
  );
}

export default SearchBar;
